.logo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-left: 35px;
}

.hexagon {
    cursor: pointer;
    width: 42px;
    height: 42px;
    transition: all 0.3s ease;
    color: #74b9ff;
}

.hexagon .hexagon-shape {
    stroke: #74b9ff;
    stroke-width: 5;
}

.hexagon:hover .hexagon-shape {
    fill: rgba(116, 185, 255, 0.2);
}

.hexagon-text {
    fill: #74b9ff;
    font-weight: bold;
    font-size: 2rem;
    background-color: transparent;
}

@media (max-width: 768px) {
    .logo-container {
        margin-left: 10px;
    }

    .hexagon {
        width: 30px;
        height: 30px;
    }
}
