.contact-section {
    background-color: #2C3E50;
    color: #ffffff;
    box-sizing: border-box;
    flex-direction: column;
}

.content-area {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
}

.contact-form {
    background: #32363e;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.2);
    width: 100%;
}

.input-icon {
    color: #74b9ff;
    margin-right: 10px;
}

.contact-button {
    width: 100%;
    background-color: #1ABC9C;
    border: none;
    transition: background-color 0.3s ease;
}

.contact-button:hover {
    background-color: #16A085;
}

.email-direct {
    margin-top: 10px;
}

.email-link {
    color: #74b9ff;
    text-decoration: none;
}

.email-link:hover {
    text-decoration: underline;
}

.toast-container {
    z-index: 1050;
    position: fixed;
    top: 1rem;
    right: 1rem;
}
