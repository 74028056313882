.about-section {
    position: relative;
    overflow: hidden;
    background-color: #1E2125;
    padding: 50px;
    box-sizing: border-box;
    color: #FFFFFF;
}

.about-header {
    text-align: center;
    margin-bottom: 2rem;
}

.content-row {
    margin-top: 3rem;
}

.profile-col {
    padding-right: 50px;
}

.skills-col {
    padding-left: 50px;
}

.profile-pic {
    height: auto;
    width: 100%;
    max-width: 160px;
    border: 4px solid #274472;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
    display: block;
    margin: auto;
}

.about-section p {
    font-size: 1.2rem;
    color: #E0E0E0;
}

.skill-set {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2rem;
}

.skill-set .skill {
    flex-basis: calc(50% - 10px);
    margin-bottom: 1rem;
}

.tooltip-inner {
    background-color: #000000;
    color: #FFFFFF;
}

.other-skills {
    margin-top: 1rem;
}

.other-skills h4 {
    margin-bottom: 0.5rem;
}

.segmented-progress-bar .progress-bar {
    width: 20%;
    border-right: 2px solid #FFFFFF;
}

.segmented-progress-bar .progress-bar:last-child {
    border-right: none;
}

.segmented-progress-bar .progress-bar.custom-filled {
    background-color: #74B9FF;
}

.segmented-progress-bar .progress-bar.custom-empty {
    background-color: #E0E0E0;
}

@media (max-width: 768px) {
    .about-section {
        padding: 20px;
    }

    .about-header {
        text-align: center;
        margin-bottom: 1rem;
    }

    .content-row {
        margin-top: 1rem;
    }

    .profile-col,
    .skills-col {
        padding-right: 10px;
        padding-left: 10px;
        margin-top: 1rem;
    }

    .profile-pic {
        margin-bottom: 1rem;
        max-width: 120px;
    }

    .about-section p {
        font-size: 1rem;
    }

    .skill-set {
        margin-top: 1rem;
    }

    .skill-set .skill {
        flex-basis: 100%;
    }

    .segmented-progress-bar .progress-bar {
        width: 25%;
    }
}