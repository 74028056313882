.experience-section {
    overflow-x: hidden;
    background-color: #2C3E50;
    color: #FFFFFF;
    padding: 50px 0;
    box-sizing: border-box;
}

.experience-section h1 {
    text-align: center;
    margin-bottom: 20px;
}

.experience-section h4,
.experience-section p {
    margin: 0;
    padding: 4px 0;
}

.timeline-container {
    position: relative;
    width: 75%;
    margin: auto;
}

.timeline-line {
    position: absolute;
    left: 50%;
    top: 0;
    bottom: 0;
    width: 2px;
    background-color: #777;
    z-index: 1;
}

.timeline-entry {
    position: relative;
    width: 50%;
    margin: 20px 0;
}

.timeline-entry.left {
    left: 0;
    text-align: right;
    padding-right: 75px;
}

.timeline-entry.right {
    left: 50%;
    text-align: left;
    padding-left: 75px;
}

.timeline-entry .timeline-circle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #000000;
    border: 2px solid #007BFF;
    position: absolute;
    top: 50%;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.timeline-entry.left .timeline-circle {
    right: 0%;
    transform: translateX(50%) translateY(-50%);
}

.timeline-entry.right .timeline-circle {
    left: 0%;
    transform: translateX(-50%) translateY(-50%);
}

.timeline-entry .card {
    background-color: #22272E;
    color: #FFFFFF;
    margin-bottom: 20px;
    border: none;
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.timeline-entry .card-header {
    background-color: #1B1F24;
    color: #55EFC4;
    border-bottom: none;
    padding: 10px 15px;
    font-size: 1.5rem;
}

.timeline-entry .card-header .period {
    font-size: 0.8rem;
    color: #9CA3AF;
}

.timeline-entry .card-body {
    padding: 15px;
    color: #FFFFFF;
    background-color: #17191C;
}

.timeline-entry .card-footer {
    background-color: #1B1F24;
    color: #FFFFFF;
    border-top: none;
    padding: 10px 15px;
}

.timeline-entry .badge {
    margin-left: 5px;
}

.timeline-entry .card:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.timeline-entry .expand-icon {
    margin-left: 10px;
    cursor: pointer;
}

.timeline-item {
    position: relative;
    padding: 10px 20px;
    background-color: #222;
    border-radius: 4px;
    z-index: 4;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.timeline-entry .timeline-connector {
    position: absolute;
    height: 2px;
    background-color: #007BFF;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
}

.timeline-entry.left .timeline-connector {
    right: 0%;
    width: calc(50% - 20px);
}

.timeline-entry.right .timeline-connector {
    left: 0%;
    width: calc(50% - 20px);
}

.timeline-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 3;
}

.timeline-entry.left .timeline-icon {
    right: 0%;
    transform: translateX(50%) translateY(-50%);
}

.timeline-entry.right .timeline-icon {
    left: 0%;
    transform: translateX(-50%) translateY(-50%);
}

.company-logo {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}

.company-initials {
    font-size: 1em;
    color: #007BFF;
    font-weight: bold;
}

.tabs {
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.tabs::-webkit-scrollbar {
    display: none;
}

.tabs .nav-link {
    display: inline-block;
    margin-right: 4px;
    padding: 8px 14px;
    cursor: pointer;
    color: #FFFFFF;
    background-color: #333;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-radius: 4px;
}

.tabs .nav-link.active {
    color: #FFFFFF;
    background-color: #555;
}

.tabs .nav-link:hover {
    background-color: #444;
}

.tabs-container .tab-content {
    background-color: #17191C;
    color: #C9D1D9;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
    margin-top: 10px;
}

.tabs-container .tab-pane h4 {
    color: #55EFC4;
    margin-bottom: 15px;
}

.tabs-container .tab-pane p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
}

.tabs-container .badge {
    font-size: 0.85rem;
    padding: 5px 10px;
}

@media (max-width: 768px) {
    .timeline-container {
        width: 100%;
    }

    .timeline-entry {
        width: 100%;
        text-align: center;
        padding: 0;
    }

    .timeline-entry.left,
    .timeline-entry.right {
        left: 0;
        padding: 0;
    }

    .timeline-entry .timeline-circle {
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
    }

    .timeline-entry .timeline-connector {
        display: none;
    }

    .tabs-container .tab-content {
        padding: 20px;
    }
}
