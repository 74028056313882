.links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    left: 55px;
    bottom: 0;
    z-index: 10;
}

.links-container::after {
    content: '';
    display: block;
    width: 1px;
    height: 100px;
    background-color: #ccc;
    margin-top: 20px;
}

.link {
    color: #666;
    margin: 10px 0;
    transition: transform 0.3s ease-out;
}

.link:hover,
.link:focus {
    transform: translateY(-3px);
}

.link-icon {
    width: 20px;
    height: 20px;
}

@media (max-width: 768px) {
    .links-container {
        left: 40px;
    }
}