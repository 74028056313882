.home-section {
    position: relative;
    overflow: hidden;
    background-color: #2C3E50;
    color: white;
    box-sizing: border-box;
}

.intro-text {
    padding-left: 23%;
}

.intro-greeting,
.intro-name,
.intro-title,
.intro-description,
.intro-button {
    transition: font-size 0.3s ease;
}

.intro-greeting {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    color: #55efc4;
}

.intro-name {
    font-size: 5rem;
    margin: 0;
    font-weight: bold;
    margin-bottom: 1rem;
    color: #FFFFFF;
}

.intro-title {
    font-size: 3rem;
    margin: 0;
    font-weight: normal;
    color: #BDC3C7;
}

.intro-description {
    font-size: 1rem;
    margin-top: 2rem;
    max-width: 520px;
    color: #BDC3C7;
}

.intro-button {
    margin-top: 2rem;
    padding: 1rem 2rem;
    font-size: 1rem;
    border: 2px solid #74b9ff;
    background-color: transparent;
    color: #74b9ff;
    border-radius: 5px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.intro-button:hover {
    background-color: rgba(116, 185, 255, 0.4);
    color: #FFFFFF;
    border-color: #74b9ff;
}

@media (max-width: 768px) {
    .intro-text {
        padding-left: 10%;
    }

    .intro-greeting {
        font-size: 1.2rem;
    }

    .intro-name {
        font-size: 3rem;
    }

    .intro-title {
        font-size: 2rem;
    }

    .intro-description {
        font-size: 0.9rem;
        padding-right: 20px;
        max-width: 100%;
    }

    .intro-button {
        font-size: 0.9rem;
        padding: 0.8rem 1.5rem;
    }
}
